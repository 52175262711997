import React, { useEffect, useState } from "react";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  Paper,
  FormControlLabel,
  Stepper,
  Step,
  StepLabel,
  MenuItem,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
  Checkbox,
  Select,
  Switch,
} from "@mui/material";
import "../../../src/App.css";
import "../forms/Form.css";
import { useInputValues } from "../../contexts/inputContext";

import { v4 as uuidv4 } from "uuid";
import { moduleInitialState } from "../../constants/inputconstants";

import { styled } from "@mui/material/styles";
import Zip from "../../assets/Zip.png";
import Download from "../../assets/Download.png";

import ConfigStep from "./ConfigStep";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../constants/constants";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormModule from "../FormModule/FormModule";

const readApiDataFromYaml = (data) => {
  if (!data) return;
  let api = [];
  if (Object.keys(data).length) {
    for (let key in data) {
      let apiData = { id: uuidv4(), apiType: key, apiPath: data[key] };
      api.push(apiData);
    }
  }
  return api ?? [];
};

const inputOptions = [
  {
    value: "TextField",
    label: "String",
  },
  {
    value: "Select",
    label: "String with Enum ",
  },
  {
    value: "DatePicker",
    label: "Date",
  },
  {
    value: "CheckBox",
    label: "boolean",
  },
];
const getFormInitialState = (data) => {
  let formList = [];
  data?.data?.children?.forEach(
    (item, index) =>
    (formList = [
      ...formList,
      ...item?.children.map((item) => {
        return {
          ...item.children[0],
          formIndex: index,
          display: item?.props?.style?.display ? false : true,
        };
      }),
    ])
  );
  console.log("formList",formList)
  return (
    formList
      .map((item) => {
        if (
          inputOptions.some((option) => option.value === item.componentName)
        ) {
          return {
            id: uuidv4(),
            componentName: item.componentName,
            name: item.props.name,
            label: item.props?.label,
            formIndex: item.formIndex,
            display: item.display,
          };
        } else return;
      })
      .filter((value) => value !== undefined) ?? []
  );
};

const themeInitialState = {
  palette: {
    type: "light",
    primary: {
      main: "",
    },
    secondary: {
      main: "",
    },
    tertiary: {
      main: "",
    },
    background: {
      default: "",
    },
    success: {
      main: "",
    },
    divider: "",
    text: {
      primary: "",
    },
  },
  font: {
    primary: {
      main: "",
    },
  },
  buttonRadius: {
    primary: {
      main: "",
    },
  },
  borderOutline: {
    primary: {
      borderColor: "#04AA6D",
      color: "green",
      hover: {
        backgroundColor: "#04AA6D",
        color: "white"
      }
    },
    secondary: {
      borderColor: "#2196F3",
      color: "dodgerblue",
      hover: {
        backgroundColor: "#2196F3",
        color: "white"
      }
    },
    tertiary: {
      borderColor: "#f44336",
      color: "red",
      hover: {
        backgroundColor: "#f44336",
        color: "white"
      }
    },
    default: {
      borderColor: "#e7e7e7",
      color: "black",
      hover: {
        backgroundColor: "#e7e7e7",
        color: "black"
      }
    }
  }
};
export const TextFieldStyled = styled(TextField)({
  marginBottom: "20px",
  "& .MuiInputLabel-root": {
    fontFamily: "ubuntu",
    fontSize: "1.1rem",
    fontWeight: "500",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: "primary.main",
    },
    "&.Mui-focused fieldset": {
      borderColor: "primary.main",
    },
  },
  boxShadow: "0px 4px 2px 0px rgba(0, 0, 0, 0.25)",
  borderRadius: "10px",
  height: "auto",
  // marginBottom: '1rem',
  width: "100%",
});

export const TextFieldSelect = styled(Select)({
  marginBottom: "20px",
  "& .MuiInputLabel-root": {
    fontFamily: "ubuntu",
    fontSize: "1.1rem",
    fontWeight: "500",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: "primary.main",
    },
    "&.Mui-focused fieldset": {
      borderColor: "primary.main",
    },
  },
  boxShadow: "0px 4px 2px 0px rgba(0, 0, 0, 0.25)",
  borderRadius: "10px",
  height: "auto",
  // marginBottom: '1rem',
  width: "100%",
});
export const CustomSwitch = styled(Switch)({
  width: 48,
  height: 24, // Reduced height for a more inset look
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      '& + .MuiSwitch-track': {
        backgroundColor: '#1976d2', // Customize checked track color
        opacity: 1,
      },
    },
    '&:not(.Mui-checked)': {
      transform: 'translateX(2px)', // Position for unchecked state
    },
  },
  '& .MuiSwitch-thumb': {
    width: 20,
    height: 20,
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)', // Soft shadow to give inset effect
    backgroundColor: '#fff',
    // margin: '2px', // Center the thumb inside the track
  },
  '& .MuiSwitch-track': {
    borderRadius: 12,
    backgroundColor: '#bdbdbd', // Unchecked state color
    opacity: 1,
    position: 'relative',
    transition: 'background-color 300ms',
  },
});

const StyledToggleButton = styled(ToggleButton)({
  height: "32px",
  width: "58px",
  "&.Mui-selected": {
    backgroundColor: "black",
    color: "white",
  },
});

// const createFormJson = (originalData, formList) => {
//   if (!formList.length) return [];
//   let updated = [];

//     const ignoreList = ["SmartButton", "Button"];
// let arr = originalData.data.children[1]

//     let d =arr.children.map((item, index) => {
//       if (ignoreList.includes(item.children[0].componentName)) {
//         return item;
//       } else {
//         return {
//           ...item,
//           props: {
//             ...item.props,
//             ...(formList[index]?.display
//               ? { style: {  } }
//               : { style: { display: 'none' } }),
//           },
//           children: [
//             {
//               ...item.children[0],
//               props: {
//                 ...item.children[0].props,
//                 name: formList[index]?.name,
//                 label: formList[index]?.label,
//                 ...(!formList[index]?.display
//                   ? { default: formList[index]?.label }
//                   : {default:''}),
//                 required: !formList[index]?.display ? false : true,
//               },
//             },
//           ],
//         }
//       }
//     });
//     updated.push(d);
//   ;

//   originalData.data.children[1].children = updated[0]
// console.log('updated',updated);
//   // const finalArr = originalData.data.children.map((item, index) => {
//   //   return { ...item, children: updated[index] };
//   // });
//   //  originalData.data.children = updated
//   return originalData.data.children;
// };

// const createFormJson = (originalData, formList) => {
//   if (!formList.length) return [];

//   const formIndexes = [...new Set(formList.map((item) => item.formIndex))];
//   const totalPages = formIndexes.length;
//   originalData.data.multiPage = totalPages;

//   let allParameters = [];
//   // Excluding header, submit button, and previous button
//   originalData.data.children.slice(1, -2).forEach(item => {
//     if (item.children && item.children.length > 0) {
//       allParameters = [...allParameters, ...item.children];
//     }
//   });

//   // Update parameters based on componentName
//   allParameters.forEach(param => {
//     if (param.componentName !== "SmartButton" && param.componentName !== "Button") {
//       const matchingFormItem = formList.find(item => item.name === param.children[0].props.name);
//       if (matchingFormItem) {
//         param.props.style = {
//           ...(param.props.style || {}),
//           display: matchingFormItem.display ? undefined : "none"
//         };
//         param.children[0].props = {
//           ...param.children[0].props,
//           label: matchingFormItem.label,
//           default: matchingFormItem.label || "",
//           required: matchingFormItem.display ? true : false
//         };
//       } else {
//         param.props.style = {
//           ...(param.props.style || {}),
//           display: "none"
//         };
//       }
//     }
//   });

//   // Now we'll create updatedChildren objects
//   const updated = [];
//   const headerObject = originalData.data.children[0];
//   const submitButtonObject = originalData.data.children[originalData.data.children.length - 1];
//   const previousButtonObject = originalData.data.children[originalData.data.children.length - 2];
//   updated.push(headerObject);

//   for (let i = 0; i < allParameters.length; i++) {
//    // const componentGroup = `grp${i + 1}`;
//     const pageParameters = allParameters.filter(param => param.props.formIndex === i + 1) || [];
//     const pageObjects = pageParameters.map(param => {
//       const updatedChildren = {
//         componentName: param.componentName,
//         props: { ...param.props },
//         children: param.children.map(child => ({ ...child }))
//       };
//       return updatedChildren;
//     });
//     updated.push(...pageObjects);
//   }

//   updated.push(previousButtonObject);
//   updated.push(submitButtonObject);

//   return updated;
// };

const createFormJson = (originalData, formList) => {
  if (!formList.length) return [];

  const formIndexes = [...new Set(formList.map((item) => item.formIndex))];
  const totalPages = formIndexes.length;
  originalData.data.multiPage = totalPages;

  let allParameters = [];
  // Excluding header, submit button, and previous button
  originalData.data.children.slice(1, -1).forEach((item) => {
    if (item.children && item.children.length > 0) {
      allParameters = [...allParameters, ...item.children];
    }
  });

  const updatedParameters = [];

  // Update parameters based on componentName
  formList.forEach((formItem) => {
    const matchingParameter = allParameters.find(
      (param) => param.children[0].props.name === formItem.name
    );
    if (matchingParameter) {
      const componentGroup = `grp${formItem.formIndex}`;
      matchingParameter.children[0].props.componentGroup = componentGroup;
      if (formItem.display === false || !("display" in formItem)) {
        console.log(matchingParameter, "matching");
        matchingParameter.props.style = {
          ...(matchingParameter.props.style || {}),
          display: "none",
        };
      } else if (formItem.display === true) {
        delete matchingParameter.props.style;
      }
      updatedParameters.push(matchingParameter);
    }
  });

  const updated = [];
  const headerObject = originalData.data.children[0];
  const submitButtonObject =
    originalData.data.children[originalData.data.children.length - 1];
  // const previousButtonObject =
  //   originalData.data.children[originalData.data.children.length - 2];
  updated.push(headerObject);

  const groupedParameters = {};
  updatedParameters.forEach((param) => {
    const componentGroup = param.children[0].props.componentGroup;
    if (!groupedParameters[componentGroup]) {
      groupedParameters[componentGroup] = [];
    }
    groupedParameters[componentGroup].push(param);
  });

  // Add each group to updated without using keys
  Object.values(groupedParameters).forEach((group) => {
    const groupNumber = parseInt(
      group[0].children[0].props.componentGroup.replace("grp", "")
    );
    const paramGroup = {
      componentName: "Grid",
      props: {
        container: true,
        rowSpacing: 2,
        columnSpacing: 1,
        dynamicStyle:
          '{{"display":formGroups.group' +
          groupNumber +
          '?"flex":"none", "justifyContent":\'center\',"width": isMobile ? "100%" : "70%","overflowY": "auto","maxHeight": isMobile ? "calc(100vh - 250px)" : "auto",  }}',
      },
    };
    paramGroup["children"] = group;
    updated.push(paramGroup);
  });

  // updated.push(previousButtonObject);
  updated.push(submitButtonObject);
  return updated;
};

function Form({ setDynamicTitle }) {

  const { inputValues, setInputValues } = useInputValues();
  console.log(inputValues,"input");
  const [selectedModuleIndex, setSelectedModuleIndex] = useState(0);

  const [selectedModuleId, setSelectedModuleId] = useState(
    inputValues?.modules[selectedModuleIndex]?._id ?? {}
  );
  const [selectedModuleFormId, setSelectedModuleFormId] = useState(
    inputValues?.modules[selectedModuleIndex]?.form_id
  );
  const [formData, setFormData] = useState([]);
  const module = inputValues.modules.length
    ? inputValues.modules[selectedModuleIndex]
    : moduleInitialState;
  const _themeInitialState = inputValues?.theme?.palette?.primary?.main
    ? inputValues?.theme
    : themeInitialState;
  const initialRows = inputValues.modules.length;
  const initialCols = 3;
  const [matrix, setMatrix] = useState(() => {
    const initialMatrix = [];
    for (let i = 0; i < initialRows; i++) {
      initialMatrix.push(Array(initialCols).fill(0));
    }
    return initialMatrix;
  });
  const updatedMatrix = [...matrix];
  const updateCellValue = (rowIndex, colIndex, value) => {
    updatedMatrix[rowIndex][colIndex] = value;
    setMatrix(updatedMatrix);
  };
console.log("updatedMatrix",updatedMatrix)
  const _listInitialState = [];

  const [activeStep, setActiveStep] = useState(0);
  const [hoveredColor, setHoveredColor] = useState("");
  const [listColumns, setListColumns] = useState(_listInitialState);
  const [listRedirect, setListRedirect] = useState("");
  const [listTitle, setListTitle] = useState("");
  const formInitial = [];

  const [moduleState, setModuleState] = useState(module);
  const [apiState, setApiState] = useState(formInitial);
  const [formList, setFormList] = useState(() => getFormInitialState(formData));

  const [dataPath, setDataPath] = useState([]);

  const [theme, setTheme] = useState(_themeInitialState);

  const [loading, setLoading] = useState(false);
  const [toggleValue, setToggleValue] = useState("list");
  const [cardType, setCardType] = useState("api");
  const { _id: moduleId, application_id, list, ...restModule } = moduleState || {};
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedNa, setIsCheckedNa] = useState(false);
  const [xAxisSelected, setXAxisSelected] = useState(false);
  const [yAxisSelected, setYAxisSelected] = useState(false);
  const [titleSelected, setTitleSelected] = useState(false);
  const [subtitleSelected, setSubtitleSelected] = useState(false);
  const [amountSelected, setAmountSelected] = useState(false);

  const updateFormList = (updatedFormList) => {
    setFormList(updatedFormList);
  };

  const {
    _id,
    createdBy,
    isYamlResponse,
    table_type,
    updatedAt,
    yamlUrl,
    ...values
  } = inputValues;
  const navigate = useNavigate();
  const handleMouseEnter = (event) => {
    setHoveredColor(event.target.value);
  };

  const handleMouseLeave = () => {
    setHoveredColor("");
  };
  const handleColorChange = (event) => {
    const { name, value } = event.target;

    // Handle nested properties
    const updatedTheme = { ...theme };
    const keys = name.split(".");
    let nestedObj = updatedTheme;

    for (let i = 0; i < keys.length - 1; i++) {
      nestedObj = nestedObj[keys[i]];
    }

    nestedObj[keys[keys.length - 1]] = value;

    setTheme(updatedTheme);
  };

  const handleChange = (e) => {
    setModuleState({ ...moduleState, [e.target.name]: e.target.value });
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCheckboxChangeNa = () => {
    setIsCheckedNa(!isCheckedNa);
  };

  useEffect(() => {
    const initialMatrix = inputValues.modules.map(() => Array(initialCols).fill(0));
    setMatrix(initialMatrix);
  }, [inputValues.modules])

  function convertToApiStateToMap(arr) {
    const result = {};
    arr.forEach((item) => {
      result[item.apiType] = item.apiPath;
    });
    return result;
  }

  const handleinputchangeDataPath = (e, index) => {
    const { name, value } = e.target;
    const list = [...dataPath];
    list[index][name] = value;
    setDataPath(list);
  };
  const handleColumnInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...listColumns];
    list[index][name] = value;
    setListColumns(list);
  };

  const handleaddColumn = () => {
    setListColumns([
      ...listColumns,
      { uuid: uuidv4(), align: "left", label: "", id: "" },
    ]);
  };
  const handleAddInput = () => {
    setFormList([
      ...formList,
      { uuid: uuidv4(), componentName: "", label: "", name: "" },
    ]);
  };
  const handleremoveFormList = (index) => {
    const list = [...formList];
    list.splice(index, 1);
    setFormList([...list]);
    setOpen(false);
  };
  const handleFormListInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...formList];
    list[index][name] = value;
    setFormList(list);
  };
  const handleCheckboxInputChange = (e, index, uuid) => {
    const { name, checked } = e.target;
    const list = [...formList];
    list
      .filter((obj) => obj.id === uuid)
      .map((obj) => {
        obj.display = checked;
      });
    setFormList(list);
  };

  const handleCheckboxInputChangeList = (e, index) => {
    const { name, checked } = e.target;
    const list = [...listColumns];
    list[index][name] = checked;
    setListColumns(list);
  };

  const handleremoveColumn = (index) => {
    const list = [...listColumns];
    list.splice(index, 1);
    setListColumns([...list]);
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      if (!selectedModuleId) return;
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      setFormList([]);
      fetch(
        `https://dev.aairavx.com/api/modules/${selectedModuleId}`,
        requestOptions
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setLoading(false);

          setModuleState(data.data);
          setApiState(readApiDataFromYaml(data.data.api));
          setDataPath(data.data.listDataPath);

          setListColumns(
            data.data.list[0]?.children[1]?.props?.columns?.map((column) => {
              return {
                uuid: uuidv4(),
                align: "left",
                label: column.label,
                id: column.id,
                display: column.display,
              };
            })
          );
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
      if (selectedModuleFormId) {
        console.log("form updating");
        fetch(
          `https://dev.aairavx.com/api/forms/${selectedModuleFormId}`,
          requestOptions
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            setLoading(false);
            setFormData(data);
            setFormList(getFormInitialState(data));
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
      }
    })();
  }, [selectedModuleId]);

  const handleToggleChange = (e) => {
    const newValue = e.target.value;
    setToggleValue(newValue);

    setInputValues({
      ...inputValues,
      appBar: {
        ...inputValues.appBar,
        showSideBar: newValue,
      },
    });
  };
const handleNextStep = async () => {
  const currentModule = inputValues.modules[selectedModuleIndex];
  const currentRow = matrix[selectedModuleIndex];

  if ((cardType==="list" && moduleState.list.length > 0 && !currentModule.isListUpdated) || (cardType==="form" && inputValues.modules[selectedModuleIndex].form_id != null &&  !currentModule.isFormUpdated)) {
    toast.info("Please Update before Continue");
    return; 
  }

  // if (cardType==="form" && inputValues.modules[selectedModuleIndex].form_id != null &&  !currentModule.isFormUpdated) {
  //   toast.info("Please update the form before continuing for this module.");
  //   return; // Stop further processing
  // }

  if (activeStep === 2) {
    setSelectedModuleIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      updateCellValue(selectedModuleIndex, 0, 1);

      inputValues.modules[selectedModuleIndex] = {
        ...inputValues.modules[selectedModuleIndex],
        isApiUpdated: true,
      };

      if (newIndex < inputValues.modules.length) {
        setSelectedModuleId(inputValues.modules[newIndex]._id);
        setSelectedModuleFormId(inputValues.modules[newIndex].form_id);
      }

      return newIndex;
    });

    const findIndicesOfZero = () => {
      for (let row = 0; row < matrix.length; row++) {
        for (let col = 0; col < matrix[row].length; col++) {
          if (matrix[row][col] === 0) {
            return { rowIndex: row, colIndex: col };
          }
        }
      }
      return null;
    };

    let indicesOfZero = findIndicesOfZero();
    console.log("indicesOfZero", indicesOfZero);

    if (indicesOfZero) {
      const isLastCell =
        indicesOfZero.rowIndex === matrix.length - 1 &&
        indicesOfZero.colIndex === matrix[0].length - 1;

      const skipStep = (rowIndex, colIndex, updateField) => {
        updateCellValue(rowIndex, colIndex, 1);
        inputValues.modules[selectedModuleIndex] = {
          ...inputValues.modules[selectedModuleIndex],
          [updateField]: true,
        };
        indicesOfZero.colIndex += 1; 
      };
      if (indicesOfZero.colIndex === 1 && moduleState.list.length === 0) {
        skipStep(indicesOfZero.rowIndex, 1, "isListUpdated");
      }

      if (
        indicesOfZero.colIndex === 2 &&
        inputValues.modules[indicesOfZero.rowIndex].form_id === null &&
        matrix[indicesOfZero.rowIndex][2] === 0
      ) {
        skipStep(indicesOfZero.rowIndex, 2, "isFormUpdated");


        const allMatrixValuesSet = matrix.every(row => row.every(value => value === 1));
        if (allMatrixValuesSet) {
          await handleApiCalls();
          return; // Exit to avoid further processing
        }

        if (!isLastCell) {
          indicesOfZero.colIndex = 0; // Reset column to the start for the next row
          setCardType("api");
        }
        return; // Early exit after reset or step increment
      }
      setSelectedModuleId(inputValues.modules[indicesOfZero.rowIndex]._id);
      setSelectedModuleFormId(inputValues.modules[indicesOfZero.rowIndex].form_id);
      setSelectedModuleIndex(indicesOfZero.rowIndex);

      switch (indicesOfZero.colIndex) {
        case 0:
          setCardType("api");
          break;
        case 1:
          setCardType("list");
          break;
        case 2:
          setCardType("form");
          break;
        default:
          console.error("Unexpected colIndex:", indicesOfZero.colIndex);
      }
    }

    // Final check: If all values are set after all iterations
    const allMatrixValuesSet = matrix.every((row) =>
      row.every((value) => value === 1)
    );

    if (allMatrixValuesSet) {
      await handleApiCalls();
      return; // Exit to avoid further processing
    }
  } else {
    setActiveStep((prevStep) => prevStep + 1);
  }
};


  // Separate function for handling API calls
  const handleApiCalls = async () => {
    try {
      setLoading(true);
      const body = { id: inputValues._id };
      delete inputValues.isYamlResponse;
      let requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      };

      if (inputValues.orgName) {
        requestOptions.body = JSON.stringify({
          id: inputValues._id,
          orgName: inputValues.orgName,
        });
      }

      const response1 = await fetch(
        `${BASE_URL}/generate${inputValues.orgName ? '/MFE' : ''}`,
        requestOptions
      );
      if (!response1.ok) {
        throw new Error("Failed to generate");
      }

      console.log(inputValues, "--------input-----");

      const response2 = await fetch(
        `${BASE_URL}/create-zip`,
        requestOptions
      );

      if (!response2.ok) {
        throw new Error("Failed to create zip");
      }

      const response3 = await fetch(
        `${BASE_URL}/upload-to-s3`,
        requestOptions
      );

      if (!response3.ok) {
        throw new Error("Failed to upload to S3");
      }

      setLoading(false);
      setSelectedModuleIndex(0);
      setActiveStep((prevStep) => prevStep + 1);
    } catch (error) {
      console.error(error);
    }
  };


  const handleBackStep = () => {
    if (activeStep === 0) {
      navigate("/");
    } else {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };
  const steps = ["Configuration", "Theme", "Modules", "Download UI Code"];

  const handleaddDataPath = () => {
    setDataPath([...dataPath, { uuid: uuidv4(), id: "", path: "" }]);
  };

  const handleremoveDataPath = (index) => {
    const list = [...dataPath];
    list.splice(index, 1);
    setDataPath([...list]);
    setOpen(false);
  };

  const handleinputchange = (e, index) => {
    const { name, value } = e.target;
    const list = [...apiState];
    list[index][name] = value;
    setApiState(list);
  };

  const downloadZip = () => {
    fetch(`${BASE_URL}/download`)
      .then((res) => res.json())
      .then((json) => {
        if (json.downloadURL) {
          const link = document.createElement("a");
          link.href = json.downloadURL;

          link.setAttribute("download", "filename.ext");
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
        } else {
          console.error(
            "No signed URL available. Make sure to fetch it first."
          );
        }
      });
  };

  // const reverseFormList = (formList) => {
  //   const reversedData = {
  //     children: formList.map((item) => ({
  //       componentName: 'Grid',
  //       props: {
  //         item: true,
  //         xs: 8
  //       },
  //       children: [
  //         {
  //           componentName: 'TextField',
  //           props: {
  //             name: item.name,
  //             label: item.label,
  //             fullWidth: "true",
  //             required: true,
  //             componentGroup: item.componentGroup || null,
  //           },
  //         },
  //       ],
  //     })),
  //   };
  // console.log(listColumns, "list");
  //   return reversedData;
  // };
  const dynamicTitle = steps[activeStep];
  // const handleCheckboxChange = (event) => {
  //   if (!event.target.checked) {
  //     // Execute your function when the checkbox is unchecked
  //     // This function will be called when the checkbox is unchecked

  //     // Add your function logic here
  //   }
  // };
  const [appId, setAppId] = useState(null);
  useEffect(() => {
    setDynamicTitle(dynamicTitle);
  }, [dynamicTitle]);

  useEffect(() => {
    if (inputValues && inputValues._id) {
      localStorage.setItem('appId', inputValues._id);
    }
  }, [inputValues]);

  useEffect(() => {
    const storedAppId = localStorage.getItem('appId');
    if (storedAppId) {
      setAppId(storedAppId);
    }
  }, []);

  useEffect(() => {
    if (appId) {
      fetchApplicationData(appId);
    }
  }, [appId]);

  if (loading)
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        disableShrink
      />
    );

  const editFormData = async () => {
    if (formList.length === 0) {
      const obj = inputValues.modules[selectedModuleIndex];
      obj.isFormUpdated = true;
      return; 
    }

    const allItemsHidden = formList.every(
      (formItem) => formItem.display === false || !("display" in formItem)
    );

    if (allItemsHidden) {
      toast.error("Please Check atleast one");
      return; // Stop execution if validation fails
    }

    if (!selectedModuleFormId) {
      updateCellValue(selectedModuleIndex, 2, 1);
      const updatedFormChildren = createFormJson(formData, formList);
      const finalData = { ...formData.data, children: updatedFormChildren };
      const obj = inputValues.modules[selectedModuleIndex];
      obj.isFormUpdated = true;

      toast.success("Form data updated successfully!");
      return;
    }

    // If there's a form ID, continue with the API call
    updateCellValue(selectedModuleIndex, 2, 1);
    const updatedFormChildren = createFormJson(formData, formList);
    const finalData = { ...formData.data, children: updatedFormChildren };

    try {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(finalData),
      };

      const response = await fetch(`${BASE_URL}/forms/${selectedModuleFormId}`, requestOptions);

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      toast.success("Form data updated successfully!");

      const reloadResponse = await fetch(`${BASE_URL}/forms/${selectedModuleFormId}`);
      const data = await reloadResponse.json();

      if (reloadResponse.ok) {
        setFormData(data);
        setFormList(getFormInitialState(data));
        const obj = inputValues.modules[selectedModuleIndex];
        obj.isFormUpdated = true;
      } else {
        throw new Error(`Error: ${reloadResponse.status} - ${reloadResponse.statusText}`);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error(`An error occurred: ${error.message}`);
    }
  };

  const editModuleApiData = async () => {
    // updateCellValue(selectedModuleIndex, 0, 1);
    try {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...restModule,
          list,
          api: convertToApiStateToMap(apiState),
          listDataPath: dataPath,
        }),
      };

      const response = await fetch(
        `${BASE_URL}/modules/${moduleState._id}`,
        requestOptions
      );


      if (response.ok) {
        const response = await fetch(`${BASE_URL}/modules/${moduleState._id}`);
        const data = await response.json();
        if (response.ok) {
          setModuleState(data.data);

          setApiState(readApiDataFromYaml(data.data.api));
          setDataPath(data.data.listDataPath);
          toast.success("Api Data updated successfully");
          const obj = inputValues.modules[selectedModuleIndex];
          const add = { isApiUpdated: true };
          Object.entries(add).forEach(([key, value]) => {
            obj[key] = value;
          });
          data.list.length &&
            setListColumns(
              data.list[0]?.children[1]?.props?.columns?.map((column) => {
                return {
                  uuid: uuidv4(),
                  align: "left",
                  label: column.label,
                  id: column.id,
                  display: column.display,
                };
              })
            );
        } else {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
      } else {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // toast.error(`An error occurred: ${error.message}`);
    }
  };

  function updateListColumns(list, listColumns, toggleValue) {
    if (list && list.length > 0 && list[0]?.children[1]?.props) {
      list[0].children[1].props.columns = listColumns.map((item) => {
        return {
          align: item.align,
          label: item.label,
          id: item.id,
          ...(toggleValue === 'graph' ? { axis: item.axis } : { type: item.type }),
          display: item.display,
        };
      });
    }
    if (list && list.length > 0 && list[0]?.children[1]?.props) {
      list[0].children[1].props.listCheckBox = isChecked;
    }

    return list ?? [];
  }

  const handleClick = (event) => {
    event.preventDefault();
    navigate("/");
  };

  const validateListColumns = () => {
    if (
      toggleValue === "graph" &&
      listColumns.every((item) => !item.axis)
    ) {
      toast.error("Please select an axis for at least one item.");
      return false;
    } else if (
      toggleValue === "card" &&
      listColumns.every((item) => !item.type)
    ) {
      toast.error("Please select an item type for at least one item.");
      return false;
    } else if (listColumns.every((item) => !item.display)) {
      toast.error("Please select 'Display Yes/No' for at least one item.");
      return false;
    }
    return true;
  };

const editModuleListData = async () => {
  if (moduleState.list.length === 0) {
    const obj = inputValues.modules[selectedModuleIndex];
    obj.isListUpdated = true;
    return; // Exit the function as there's no list data to update
  }

  const isValid = validateListColumns(); // This should return true or false
  if (!isValid) {
    return; // Stops execution if validation fails
  }

  updateCellValue(selectedModuleIndex, 1, 1);

  // Determine if showTabs should be set to true
  console.log("selectedModuleIndex", selectedModuleIndex);

  const shouldSetShowTabs = inputValues.sideBar.navItems.some((item, index) => {
    const condition =
      index === selectedModuleIndex && // Check if index matches selectedModuleIndex
      item.composite && 
      item.composite.length > 0 && 
      item.showTabs === true; // Strictly check if showTabs is true
    console.log(`Index: ${index}, Item: ${item.title}, Condition Met:`, condition);
    return condition;
  });
  
  console.log("shouldSetShowTabs", shouldSetShowTabs);
  
  try {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...restModule,
        list: updateListColumns(moduleState.list, listColumns, toggleValue),
        api: convertToApiStateToMap(apiState),
        listDataPath: dataPath,
        listStyle: toggleValue,
        showTabs: shouldSetShowTabs,
      }),
    };
    console.log("requestOptions",requestOptions.body)

    const response = await fetch(
      `${BASE_URL}/modules/${moduleState._id}`,
      requestOptions
    );

    if (response.ok) {
      toast.success("List Data updated successfully");
      fetch(`https://dev.aairavx.com/api/modules/${selectedModuleId}`)
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          setModuleState(data.data);
          setApiState(readApiDataFromYaml(data.data.api));
          setDataPath(data.data.listDataPath);

          const obj = inputValues.modules[selectedModuleIndex];
          const add = { isListUpdated: true };
          Object.entries(add).forEach(([key, value]) => {
            obj[key] = value;
          });

          setListColumns(
            data.data.list[0]?.children[1]?.props?.columns?.map((column) => {
              return {
                uuid: uuidv4(),
                align: "left",
                label: column.label,
                id: column.id,
                ...(toggleValue === 'graph' ? { axis: column.axis } : { type: column.type }),
                display: column.display,
              };
            })
          );
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          toast.error(`An error occurred: ${error.message}`);
        });
    }
  } catch (error) {
    console.error("An error occurred:", error);
    toast.error(`An error occurred: ${error.message}`);
  }
};


  const fetchApplicationData = async (id) => {
    try {
      const response = await fetch(`https://dev.aairavx.com/api/applications/${id}`);

      if (response.ok) {
        const responseData = await response.json();
        setInputValues({
          ...responseData.data,
          isYamlResponse: true,
          orgName: inputValues.orgName,
        });
      } else {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error(`An error occurred: ${error.message}`);
    }
  };

  const editApplicationData = async () => {
    try {
      const updatedTheme = { ...theme };

      delete updatedTheme.borderOutline.currentOutline;
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...values, theme: updatedTheme }),
      };

      const response = await fetch(
        `https://dev.aairavx.com/api/applications/${inputValues._id}`,
        requestOptions
      );

      if (response.ok) {
        const responseData = await response.json();
        setInputValues({
          ...responseData.data,
          isYamlResponse: true,
          orgName: inputValues.orgName,
        });
        toast.success("Data updated successfully");
      } else {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error(`An error occurred: ${error.message}`);
    }
  };
  const updateApplicationData = async () => {
    try {
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
      }

      const response = await fetch(
        `${BASE_URL}/applications/${inputValues._id}`,
        requestOptions
      )

      if (response.ok) {
        const response = await fetch(
          `${BASE_URL}/applications/${inputValues._id}`
        )
        console.log("update")
        toast.success('Data updated successfully')
        const data = await response.json()
        if (response.ok) {
          setInputValues({
            ...data?.data,
            isYamlResponse: true,
            orgName: inputValues.orgName,
          })
        } else {
          throw new Error(`Error: ${response.status} - ${response.statusText}`)
        }
      } else {
        throw new Error(`Error: ${response.status} - ${response.statusText}`)
      }
    } catch (error) {
      console.error('An error occurred:', error)
      toast.error(`An error occurred: ${error.message}`)
    }
  }

  const handleAxisChange = (e, index) => {
    const { value } = e.target;

    setListColumns((prevColumns) => {
      const updatedColumns = prevColumns.map((item, i) => {
        if (i === index) {
          if (item.axis === 'x') setXAxisSelected(false);
          if (item.axis === 'y') setYAxisSelected(false);

          if (value === 'x') setXAxisSelected(true);
          if (value === 'y') setYAxisSelected(true);

          return {
            ...item,
            axis: value,
            display: true,
          };
        }
        return item;
      });

      const xAxis = updatedColumns.find((item) => item.axis === 'x');
      const yAxis = updatedColumns.find((item) => item.axis === 'y');
      const others = updatedColumns.filter((item) => item.axis !== 'x' && item.axis !== 'y');

      return [xAxis, yAxis, ...others].filter(Boolean); // Return rearranged list, filtering out any `null` or `undefined`
    });

    console.log("item", listColumns);

    // Set selected state based on current selection after the update
    if (value === 'x') setXAxisSelected(true);
    if (value === 'y') setYAxisSelected(true);
  };
  const handleItemTypeChange = (e, index) => {
    const { value } = e.target;

    setListColumns((prevColumns) => {
      const updatedColumns = prevColumns.map((item, i) => {
        if (i === index) {
          if (item.type === 'title') setTitleSelected(false);
          if (item.type === 'subtitle') setSubtitleSelected(false);
          if (item.type === 'amount') setAmountSelected(false);

          // Set new selections based on current input value
          if (value === 'title') setTitleSelected(true);
          if (value === 'subtitle') setSubtitleSelected(true);
          if (value === 'amount') setAmountSelected(true);

          return {
            ...item,
            type: value,
            display: true,
          };
        }
        return item;
      });

      const title = updatedColumns.find((item) => item.type === 'title');
      const subtitle = updatedColumns.find((item) => item.type === 'subtitle');
      const amount = updatedColumns.find((item) => item.type === 'amount');
      const others = updatedColumns.filter((item) => item.type !== 'title' && item.type !== 'subtitle' && item.type !== 'amount');

      // Return the filtered and reordered columns
      return [title, subtitle, amount, ...others].filter(Boolean);
    });

    // Instead of logging old state, log inside the setState callback
    setListColumns((updatedListColumns) => {
      console.log("Updated columns:", updatedListColumns);
      return updatedListColumns;  // Return the updated state
    });
  };

  const handleThemeChange = (event) => {
    const { value } = event.target;

    setTheme((prevConfig) => ({
      ...prevConfig,
      font: {
        ...prevConfig.font,
        primary: {
          ...prevConfig.font.primary,
          main: value,
        },
      },
    }));
  };
  const handleRadiusChange = (event) => {
    const { value } = event.target;

    setTheme((prevConfig) => ({
      ...prevConfig,
      buttonRadius: {
        ...prevConfig.buttonRadius,
        primary: {
          ...prevConfig.buttonRadius.primary,
          main: value,
        },
      },
    }));
  };

  const handleBorderChange = (event) => {
    const { value } = event.target;

    setTheme((prevState) => ({
      ...prevState,
      borderOutline: {
        ...prevState.borderOutline,
        currentOutline: value,
        primary: prevState.borderOutline[value]
      },
    }));
  };

  return (
    <Box className="main" sx={{ padding: "2% 15% 5% 15%", margin: "0" }}>
      <Paper
        sx={{
          padding: "30px 45px 0 45px",
          width: "100%",

          position: "relative",
        }}
      >
        {/* <Box className='upper-cirle'></Box>
           <Box className='lower-cirle'></Box> */}
        <Box sx={{ display: "flex", marginBottom: "1rem" }}>
          <button
            onClick={handleBackStep}
            style={{
              all: "unset",
              marginRight: "6rem",
              padding: "0",
              display: "flex",
              alignItems: "center",
              backgroundColor: "white",
              color: "#000000",
              cursor: "pointer",

              flexGrow: "0",
              fontFamily: "Ubuntu, sans-serif",
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            <svg
              width="10"
              height="19"
              viewBox="0 0 10 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 17.625L2 9.3125L9 1"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            &nbsp; &nbsp; Back
          </button>
          <Box
            sx={{
              flexGrow: "1",
              display: "flex",
              justifyContent: "center",
              marginRight: "9rem",
            }}
          >
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{ color: "#000000" }}
            >
              {steps.map((label) => (
                <Step sx={{ color: "#000000" }} key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
        <Box sx={{ padding: "16px" }}>
          {activeStep === 0 && <ConfigStep />}
          {activeStep === 2 && (
            <Box className="sideBar" sx={{ display: "flex" }}>
              <Box sx={{ marginTop: "4.5rem" }}>
                <Box className="sideBarItemsContainer">

                  {inputValues.modules?.length &&
                    inputValues.modules?.map((module, index) => (
                      <Box key={module?._id} className="moduleContainer">
                        <Typography
                          style={{
                            color:
                              moduleState?.name === module.name
                                ? "#0077b6"
                                : "black",
                            textDecoration:
                              moduleState?.name === module.name
                                ? "underline"
                                : "none",
                          }}
                          className={`sideBarItems ${index === inputValues.modules?.length - 1
                            ? "hideSidebar"
                            : ""
                            }`}
                          onClick={() => {
                            if (module._id === selectedModuleId) return;
                            setFormList([]);
                            setSelectedModuleId(module._id);
                            setSelectedModuleFormId(module.form_id);
                            setSelectedModuleIndex(index);
                            // setCardType("api");

                          }}
                        >
                          {module.name}
                        </Typography>
                        {/* <Box className="circleContainer" key={updatedMatrix.length}>
        
                    <Box className="circle"   style={{backgroundColor:getColorForValue(updatedMatrix[selectedModuleIndex][0])}}></Box>
                    <Box className="circle"   style={{backgroundColor:getColorForValue(updatedMatrix[selectedModuleIndex][1])}}></Box>
                    <Box className="circle"   style={{backgroundColor:getColorForValue(updatedMatrix[selectedModuleIndex][2])}}></Box>  
                          </Box>  */}
                        <Box className="circleContainer">
                          <Box
                            className="circle"
                            style={{
                              backgroundColor:
                                module.isApiUpdated === true
                                  ? "lightgreen"
                                  : "red",
                            }}
                          ></Box>
                          {moduleState.list.length>0 &&(
                          <Box
                            className="circle"
                            style={{
                              backgroundColor:
                                module.isListUpdated === true
                                  ? "lightgreen"
                                  : "red",
                            }}
                          ></Box>
                         )}
                          {formList.length>0 &&(
                          <Box
                            className="circle"
                            style={{
                              backgroundColor:
                                module.isFormUpdated === true
                                  ? "lightgreen"
                                  : "red",
                            }}
                          ></Box>
                          )}
                        </Box>
                      </Box>
                    ))}
                </Box>
              </Box>
              <Box className="navigationContainer">
                <Box className="navigationItems">
                  <Box
                    className="navigationButton"
                    style={{
                      borderBottom:
                        cardType === "api" ? "2px solid black" : null,
                        transition: "border-bottom 0.3s ease", // Adds smooth transition effect

                    }}
                    onClick={() => setCardType("api")}
                  >
                    API Module
                  </Box>

                  {moduleState.list.length > 0 && (
                    <Box
                      className="navigationButton"
                      style={{
                        borderBottom: cardType === "list" ? "3px solid black" : null,
                        transition: "border-bottom 0.3s ease", // Adds smooth transition effect
                      }}
                      onClick={() => setCardType("list")}
                    >
                      List Module
                    </Box>
                  )}
                  {formList.length > 0 && (
                    <Box
                      className="navigationButton"
                      style={{
                        borderBottom:
                          cardType === "form" ? "3px solid black" : null,
                          transition: "border-bottom 0.3s ease", // Adds smooth transition effect

                      }}
                      onClick={() => setCardType("form")}
                    >
                      Form Module
                    </Box>
                  )}

                </Box>
                <Box className="moduleContainer">
                  {" "}
                  <ToastContainer position="bottom-center" />
                  <Box
                    sx={{
                      maxHeight: "500px",
                      // overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    {cardType === "api" ? (
                      <Box>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            xs={12}
                          >
                            {" "}
                          </Grid>
                          <Grid item xs={10}>
                            <TextFieldStyled
                              fullWidth
                              name="name"
                              required
                              value={moduleState?.name}
                              label="Module Name"
                              sx={{ marginLeft: "15px" }}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid item xs={9}>
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: "Ubuntu, sans-serif",
                                fontWeight: "500",
                                fontSize: "22px",
                                lineHeight: "25px",
                                color: "#1C242C",
                                textAlign: "left",
                                margin: "12px",
                              }}
                              gutterBottom
                            >
                              API:
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={3}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {/* <Button
                                sx={{
                                  marginLeft: "15px",
                                  marginTop: "0.75rem",
                                  marginBottom: "0.75rem",
                                  borderRadius: "30px",
                                  padding: "8px 40px",
                                  backgroundColor: "#1C242C",
                                  color: "#fff",
                                  cursor: "pointer",
                                  height: "45px",
                                  flexGrow: "0",
                                  fontFamily: "Ubuntu, sans-serif",

                                  fontSize: "18px",
                                  fontWeight: "700",
                                  lineHeight: "normal",
                                  textTransform: "none",
                                }}
                                variant="contained"
                                color="primary"
                                onClick={handleaddclick}
                              >
                                <AddIcon aria-label="add" />
                                API
                              </Button> */}
                          </Grid>
                        </Grid>
                        <div>
                          {apiState?.map((item, i) => (
                            <Grid
                              container
                              spacing={2}
                              key={item.id}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "1px",
                              }}
                            >
                              <Grid item xs={5} sx={{ marginTop: "1rem" }}>
                                <TextFieldStyled
                                  select
                                  label={`API Type`}
                                  name="apiType"
                                  variant="outlined"
                                  fullWidth
                                  value={item?.apiType}
                                  onChange={(e) => handleinputchange(e, i)}
                                >
                                  <MenuItem value="getApi">GET</MenuItem>
                                  <MenuItem value="postApi">POST</MenuItem>
                                  <MenuItem value="putApi">PUT</MenuItem>
                                  <MenuItem value="deleteApi">
                                    DELETE
                                  </MenuItem>
                                  <MenuItem value="listApi">LIST</MenuItem>
                                </TextFieldStyled>
                              </Grid>
                              <Grid item xs={5}>
                                <TextFieldStyled
                                  label={`API Path`}
                                  variant="outlined"
                                  name="apiPath"
                                  value={item?.apiPath}
                                  onChange={(e) => handleinputchange(e, i)}
                                  fullWidth
                                  sx={{ marginTop: "1rem" }}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                {/* <Button
                                    sx={{
                                      marginTop: "1rem",
                                      padding: "8px 40px",
                                      backgroundColor: "#1C242C",
                                      color: "#fff",
                                      cursor: "pointer",
                                      height: "45px",
                                      flexGrow: "0",
                                      fontFamily: "Ubuntu, sans-serif",
                                      marginRight: "20px",
                                      fontSize: "18px",
                                      fontWeight: "700",
                                      borderRadius: "30px",
                                      lineHeight: "normal",
                                    }}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleremove(i)}
                                  >
                                    Remove
                                  </Button> */}
                                {/* <IconButton class="delete-icon">
                                    <DeleteIcon
                                      variant="contained"
                                      aria-label="delete"
                                      onClick={() => handleremove(i)}
                                    />
                                  </IconButton> */}
                              </Grid>
                            </Grid>
                          ))}
                        </div>
                        <Typography
                          variant="h6"
                          sx={{
                            fontFamily: "Ubuntu, sans-serif",
                            fontWeight: "500",
                            fontSize: "22px",
                            lineHeight: "25px",
                            color: "#1C242C",
                            textAlign: "left",
                            margin: "12px",
                          }}
                          gutterBottom
                        >
                          Bind list data object in the DOM:
                        </Typography>
                        <div>
                          {dataPath.length ? (
                            dataPath?.map((item, i) => (
                              <Grid
                                container
                                spacing={2}
                                key={item.uuid}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: "1px",
                                }}
                              >
                                <Grid item xs={5} sx={{ marginTop: "1rem" }}>
                                  <TextFieldStyled
                                    label={`Id`}
                                    name="id"
                                    variant="outlined"
                                    value={item.id}
                                    fullWidth
                                    onChange={(e) =>
                                      handleinputchangeDataPath(e, i)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={5}>
                                  <TextFieldStyled
                                    label={`Path`}
                                    variant="outlined"
                                    value={item.path}
                                    name="path"
                                    onChange={(e) =>
                                      handleinputchangeDataPath(e, i)
                                    }
                                    fullWidth
                                    sx={{ marginTop: "1rem" }}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  {/* <Button
                                      sx={{
                                        marginTop: "1rem",
                                        padding: "8px 40px",
                                        backgroundColor: "#1C242C",
                                        color: "#fff",
                                        cursor: "pointer",
                                        height: "45px",
                                        flexGrow: "0",
                                        fontFamily: "Ubuntu, sans-serif",
                                        marginRight: "20px",
                                        fontSize: "18px",
                                        fontWeight: "700",
                                        borderRadius: "30px",
                                        lineHeight: "normal",
                                      }}
                                      variant="contained"
                                      color="primary"
                                      onClick={() => handleremoveDataPath(i)}
                                    >
                                      Remove
                                    </Button> */}

                                  <React.Fragment>
                                    <IconButton className="delete-icon">
                                      <DeleteIcon
                                        variant="contained"
                                        aria-label="delete"
                                        onClick={handleClickOpen}
                                      />
                                    </IconButton>
                                    <Dialog
                                      open={open}
                                      onClose={handleClose}
                                      aria-labelledby="alert-dialog-title"
                                      aria-describedby="alert-dialog-description"
                                    >
                                      <DialogTitle id="alert-dialog-title">
                                        {"Delete"}
                                      </DialogTitle>
                                      <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                          Are you sure you want to permanently
                                          delete?
                                        </DialogContentText>
                                      </DialogContent>
                                      <DialogActions>
                                        <Button
                                          onClick={() =>
                                            handleremoveDataPath(i)
                                          }
                                          autoFocus
                                        >
                                          Yes
                                        </Button>
                                        <Button onClick={handleClose}>
                                          No
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                  </React.Fragment>

                                  {/* <IconButton class="delete-icon">
                                      <DeleteIcon
                                        variant="contained"
                                        aria-label="delete"
                                        onClick={
                                          handleClickOpen
                                        }
                                        
                                       // onClick={() => handleremoveDataPath(i)}
                                      />
                                    </IconButton> */}
                                </Grid>
                              </Grid>
                            ))
                          ) : (
                            <Typography
                              variant="h6"
                              sx={{ marginLeft: "1rem", color: "red" }}
                            >
                              Data path list is not available for this module
                            </Typography>
                          )}

                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                className="button-data-path"
                                sx={{
                                  marginLeft: "15px",
                                  marginTop: "0.75rem",
                                  marginBottom: "0.75rem",
                                  borderRadius: "30px",
                                  padding: "8px 40px",
                                  backgroundColor: "#1C242C",
                                  color: "#fff",
                                  cursor: "pointer",
                                  height: "45px",
                                  flexGrow: "0",
                                  fontFamily: "Ubuntu, sans-serif",

                                  fontSize: "18px",
                                  fontWeight: "700",
                                  lineHeight: "normal",
                                  textTransform: "none",
                                }}
                                variant="contained"
                                color="primary"
                                onClick={handleaddDataPath}
                              >
                                <AddIcon aria-label="add" />
                                Data Path
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                        {/* <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextFieldStyled
                                fullWidth
                                required
                                name="payloadPath"
                                value={moduleState?.payloadPath}
                                onChange={handleChange}
                                label="Payload Path"
                                sx={{ marginLeft: "15px" }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextFieldStyled
                                fullWidth
                                required
                                value={moduleState?.successRedirect}
                                name="successRedirect"
                                onChange={handleChange}
                                label="Success Redirect"
                                sx={{ marginLeft: "15px" }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextFieldStyled
                                fullWidth
                                required
                                name="failureRedirect"
                                value={moduleState?.failureRedirect}
                                onChange={handleChange}
                                label="Failure Redirect"
                                sx={{ marginLeft: "15px" }}
                              />
                            </Grid>
                          </Grid> */}
                      </Box>
                    ) : null}

                    {cardType === "list" && (
                      <>
                        <Grid
                          container
                          spacing={2}
                          sx={{ alignItems: "flex-start" }}
                        >
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            xs={12}
                          >
                            {" "}
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={7}>
                              {moduleState.list && moduleState.list.length > 0 && (
                                <Typography
                                  variant="h6"
                                  sx={{
                                    fontWeight: "500",
                                    lineHeight: "35px",
                                    fontSize: "25px",
                                    color: "black",
                                    fontFamily: "Ubuntu",
                                    padding: "15px 0",
                                    margin: "0",
                                    marginLeft: "30px",
                                  }}
                                  gutterBottom
                                >
                                  {moduleState?.name} List Module :
                                </Typography>
                              )}
                            </Grid>
                            {moduleState?.name === "Deposits" && moduleState.list.length > 0 && (
                              <Grid item xs={5}>
                                <FormControlLabel
                                  sx={{ margin: "12px" }}
                                  control={
                                    <Checkbox
                                      name="listCheckBox"
                                      checked={isCheckedNa}
                                      onChange={handleCheckboxChangeNa}
                                    />
                                  }
                                  label="Not Applicable"
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <div>
                          {listColumns?.length > 0 && (
                            <>
                              <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                                <Grid item xs={6}>
                                  <Typography
                                    sx={{
                                      fontFamily: "Ubuntu, sans-serif",
                                      fontWeight: "500",
                                      fontSize: "22px",
                                      lineHeight: "25px",
                                      color: "#1C242C",
                                      textAlign: "left",
                                    }}
                                    variant="h4"
                                    component="h1"
                                    gutterBottom
                                  >
                                    How do you want to show the lists?
                                  </Typography>
                                </Grid>
                              <Grid item xs={4}>
                                  <TextFieldStyled
                                    select
                                    label="View Type"
                                    value={toggleValue}
                                    variant="outlined"
                                    onChange={handleToggleChange}
                                    fullWidth
                                    displayEmpty
                                  >
                                    <MenuItem value="list">Table</MenuItem>
                                    <MenuItem value="card">Tile</MenuItem>
                                    <MenuItem value="graph">Chart</MenuItem>
                                  </TextFieldStyled>
                                </Grid>
                              </Grid>


                              {/* {toggleValue === "card" && (
                              <Box item xs={12}>
                                <Typography
                                  sx={{
                                    fontFamily: "Ubuntu, sans-serif",
                                    fontWeight: "400",
                                    fontSize: "18px",
                                    lineHeight: "22px",
                                    color: "red",
                                    textAlign: "left",
                                    margin: "1rem",
                                  }}
                                >
                                  Tile will be supported soon.
                                </Typography>
                              </Box>
                            )} */}

                              {/* <Grid
                              container
                              spacing={2}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "1px",
                              }}
                            >
                              <Grid item xs={5}>
                                <TextFieldStyled
                                  label={`Title`}
                                  name="title"
                                  variant="outlined"
                                  fullWidth
                                  value={
                                    moduleState?.list[0]?.children[2]?.props
                                      ?.title ?? listTitle
                                  }
                                  onChange={(e) => {
                                    setListTitle(e.target.value);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={5}>
                                <TextFieldStyled
                                  label={`Redirect Path`}
                                  name="redirectPath"
                                  variant="outlined"
                                  fullWidth
                                  value={
                                    moduleState?.list[0]?.children[1]?.props
                                      ?.redirectTo ?? listRedirect
                                  }
                                  onChange={(e) => {
                                    setListRedirect(e.target.value);
                                  }}
                                />
                              </Grid>
                            </Grid> */}

                              <Grid container spacing={2}>
                                <Grid item xs={4.5}>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    sx={{ margin: "12px" }}
                                  >
                                    List Api Columns :
                                  </Typography>
                                </Grid>
                                <Grid item xs={4.5}>
                                  {toggleValue !== "graph" && (
                                    <FormControlLabel
                                      sx={{ margin: "12px" }}
                                      control={
                                        <Checkbox
                                          name="listCheckBox"
                                          checked={isChecked}
                                          value={
                                            moduleState?.list[0]?.children[1]?.props?.listCheckBox ??
                                            isChecked
                                          }
                                          onChange={(e) => {
                                            setIsChecked(e.target.checked);
                                          }}
                                        />
                                      }
                                      label="Checkbox Required?"
                                      labelPlacement="start"
                                    />
                                  )}
                                </Grid>

                                <Grid
                                  item
                                  xs={3}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Button
                                    sx={{
                                      marginLeft: "15px",
                                      marginTop: "0.75rem",
                                      borderRadius: "30px",
                                      padding: "8px 40px",
                                      backgroundColor: "#1C242C",
                                      color: "#fff",
                                      cursor: "pointer",
                                      height: "45px",
                                      flexGrow: "0",
                                      fontFamily: "Ubuntu, sans-serif",

                                      fontSize: "18px",
                                      fontWeight: "700",
                                      lineHeight: "normal",
                                      textTransform: "none",
                                    }}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleaddColumn}
                                  >
                                    <AddIcon aria-label="add" />
                                    Column
                                  </Button>{" "}
                                </Grid>
                              </Grid>
                            </>
                          )}
                          <div>
                            {listColumns?.length ? (
                              listColumns?.map((item, i) => (
                                <Grid
                                  container
                                  spacing={2}
                                  key={item.uuid}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "1px",
                                  }}
                                >
                                  <Grid item xs={2}>
                                    {toggleValue === "graph" ? (
                                      <TextFieldStyled
                                        select
                                        label="Axis"
                                        value={item.axis || ""}
                                        variant="outlined"
                                        onChange={(e) => handleAxisChange(e, i)}
                                        fullWidth
                                        displayEmpty
                                      >
                                        <MenuItem value="">Change</MenuItem> {/* Add None option */}
                                        <MenuItem value="x" disabled={xAxisSelected && item.axis !== "x"}>
                                          X Axis
                                        </MenuItem>
                                        <MenuItem value="y" disabled={yAxisSelected && item.axis !== "y"}>
                                          Y Axis
                                        </MenuItem>
                                      </TextFieldStyled>
                                    ) : toggleValue === "card" ? (
                                      // Show Item Type dropdown when toggleValue is "tile"
                                      <TextFieldStyled
                                        select
                                        label="Select Item Type"
                                        value={item.type || ""} // Assuming `type` property in item state
                                        variant="outlined"
                                        onChange={(e) => handleItemTypeChange(e, i)}
                                        fullWidth
                                        displayEmpty
                                      >
                                        <MenuItem value="">Change</MenuItem> {/* Add None option */}
                                        <MenuItem value="title" disabled={titleSelected && item.type !== "title"}>
                                          Title
                                        </MenuItem>
                                        <MenuItem value="subtitle" disabled={subtitleSelected && item.type !== "subtitle"}>
                                          Subtitle
                                        </MenuItem>
                                        <MenuItem value="amount" disabled={amountSelected && item.type !== "amount"}>
                                          Amount
                                        </MenuItem>
                                        <MenuItem value="list">List</MenuItem>
                                      </TextFieldStyled>
                                    ) : (
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name="display"
                                            checked={item.display}
                                            onChange={(e) => handleCheckboxInputChangeList(e, i)}
                                          />
                                        }
                                        label="Display Yes/No"
                                      />
                                    )}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    sx={{ marginTop: "1rem" }}
                                  >
                                    <TextFieldStyled
                                      label={`Parameter Name`}
                                      name="id"
                                      variant="outlined"
                                      value={item.id}
                                      fullWidth
                                      onChange={(e) =>
                                        handleColumnInputChange(e, i)
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <TextFieldStyled
                                      label={`Display Name`}
                                      variant="outlined"
                                      name="label"
                                      value={item.label}
                                      onChange={(e) =>
                                        handleColumnInputChange(e, i)
                                      }
                                      fullWidth
                                      sx={{ marginTop: "1rem" }}
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    {/* <Button
                                        sx={{
                                          marginTop: "1rem",
                                          padding: "8px 40px",
                                          backgroundColor: "#1C242C",
                                          color: "#fff",
                                          cursor: "pointer",
                                          height: "45px",
                                          flexGrow: "0",
                                          fontFamily: "Ubuntu, sans-serif",
                                          marginRight: "20px",
                                          fontSize: "18px",
                                          fontWeight: "700",
                                          borderRadius: "30px",
                                          lineHeight: "normal",
                                        }}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleremoveColumn(i)}
                                      >
                                        Remove
                                      </Button> */}

                                    <React.Fragment>
                                      <IconButton className="delete-icon">
                                        <DeleteIcon
                                          variant="contained"
                                          aria-label="delete"
                                          onClick={handleClickOpen}
                                        // onClick={() => handleremoveColumn(i)}
                                        />
                                      </IconButton>
                                      <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                      >
                                        <DialogTitle id="alert-dialog-title">
                                          {"Delete"}
                                        </DialogTitle>
                                        <DialogContent>
                                          <DialogContentText id="alert-dialog-description">
                                            Are you sure you want to
                                            permanently delete?
                                          </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                          <Button
                                            onClick={() =>
                                              handleremoveColumn(i)
                                            }
                                            autoFocus
                                          >
                                            Yes
                                          </Button>
                                          <Button onClick={handleClose}>
                                            No
                                          </Button>
                                        </DialogActions>
                                      </Dialog>
                                    </React.Fragment>

                                    {/* <IconButton class="delete-icon">
                                        <DeleteIcon
                                          variant="contained"
                                          aria-label="delete"
                                          onClick={() => handleremoveColumn(i)}
                                        />
                                      </IconButton> */}
                                  </Grid>
                                </Grid>
                              ))
                            ) : (
                              <>
                                {/* {update && setUpdate(false)} */}
                                <Typography
                                  variant="h5"
                                  sx={{
                                    paddingTop: '150px',
                                    marginLeft: "1rem",
                                    color: "red",
                                    textAlign: "center",
                                    fontWeight: "600"
                                  }}                            >
                                  List is not available for this module
                                </Typography>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {cardType === "form" ? (
                      <FormModule
                        selectedModuleIndex={selectedModuleIndex}
                        formData={formData}
                        formList={formList}
                        handleAddInput={handleAddInput}
                        handleCheckboxInputChange={handleCheckboxInputChange}
                        handleFormListInputChange={handleFormListInputChange}
                        handleremoveFormList={handleremoveFormList}
                        handleToggleChange={handleToggleChange}
                        handleChange={handleChange}
                        moduleState={moduleState}
                        handleClickOpen={handleClickOpen}
                        handleClose={handleClose}
                        toggleValue={toggleValue}
                        setToggleValue={setToggleValue}
                        open={open}
                        updateFormList={updateFormList}
                        setFormList={setFormList}
                        selectedModuleFormId={selectedModuleFormId}
                      />
                    ) : null}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {activeStep === 3 && (
            <div sx={{ minHeight: "60vh" }}>
              <Typography
                sx={{
                  fontFamily: "Ubuntu, sans-serif",
                  fontWeight: "700",
                  fontSize: "40px",
                  lineHeight: "45.96px",
                  color: "#1C242C",
                  textAlign: "left",
                }}
                variant="h4"
                component="h1"
                gutterBottom
              >
                ReactJS APP Generator
              </Typography>

              <Typography
                sx={{
                  fontFamily: "Ubuntu, sans-serif",
                  fontWeight: "500",
                  fontSize: "25px",
                  lineHeight: "45.96px",
                  color: "#1C242C",
                  textAlign: "left",
                }}
                variant="h4"
                component="h1"
                gutterBottom
              >
                Please find the below generated UI code
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Ubuntu, sans-serif",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "45.96px",
                  color: "#1C242C",
                  textAlign: "left",
                }}
                variant="h4"
                component="h1"
                gutterBottom
              >
                UI Code
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "50%",

                    backgroundBlendMode: "screen",
                    backgroundColor: "white",
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                    padding: 1,
                  }}
                >
                  {" "}
                  <img alt="Zip" src={Zip} />
                  <Typography
                    sx={{
                      fontFamily: "Ubuntu, sans-serif",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "45.96px",
                      color: "#1C242C",
                      textAlign: "left",
                    }}
                    variant="h4"
                    component="h1"
                    gutterBottom
                  >
                    {inputValues?.appName}.Zip
                  </Typography>
                </Box>
                <button
                  style={{ backgroundColor: "white", marginLeft: "1rem" }}
                  onClick={downloadZip}
                >
                  <img
                    sx={{ width: "24px", height: "24px" }}
                    alt="Download"
                    src={Download}
                  />
                </button>
              </Box>
            </div>
          )}
          {activeStep === 1 && (
            <div>
              {" "}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {" "}
                <ToastContainer position="bottom-center" />
              </Box>{" "}
              <Typography
                sx={{
                  fontFamily: "Ubuntu, sans-serif",
                  fontWeight: "600",
                  fontSize: "22px",
                  lineHeight: "25.28px",
                  color: "#1C242C",
                  textAlign: "left",
                  marginBottom: "1rem",
                }}
                gutterBottom
              >
                Choose your colors
              </Typography>
              <Grid container spacing={4} className="theme">
                <Grid
                  className="color-wrapper"
                  item
                  xs={4}
                  sx={{ marginTop: "1rem", paddingTop: "1px" }}
                >
                  <input
                    className={`${theme.palette.primary.main === "#ffffff"
                      ? "input-border"
                      : ""
                      }`}
                    type="color"
                    name="palette.primary.main"
                    value={theme.palette.primary.main}
                    onChange={handleColorChange}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    title={hoveredColor} // dynamically update the title attribute
                  />
                  <label
                    htmlFor="Primary"
                    style={{
                      fontWeight: "600",
                      fontSize: "1.5rem",
                      marginLeft: "10px",
                    }}
                  >
                    Primary
                  </label>
                </Grid>

                <Grid
                  className="color-wrapper"
                  item
                  xs={4}
                  sx={{ marginTop: "1rem" }}
                >
                  {" "}
                  <input
                    type="color"
                    value={theme.palette.secondary.main}
                    name="palette.secondary.main"
                    onChange={handleColorChange}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  />{" "}
                  <label
                    htmlFor="Secondary"
                    style={{
                      fontWeight: "600",
                      fontSize: "1.5rem",
                      marginLeft: "10px",
                    }}
                  >
                    Secondary
                  </label>
                </Grid>
                <Grid
                  className="color-wrapper"
                  item
                  xs={4}
                  sx={{ marginTop: "1rem" }}
                >
                  {" "}
                  <input
                    type="color"
                    value={theme.palette.tertiary.main}
                    name="palette.tertiary.main"
                    onChange={handleColorChange}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  />
                  <label
                    htmlFor="Tertiary"
                    style={{
                      fontWeight: "600",
                      fontSize: "1.5rem",
                      marginLeft: "10px",
                    }}
                  >
                    Tertiary
                  </label>
                </Grid>
              </Grid>
              <Typography
                sx={{
                  fontFamily: "Ubuntu, sans-serif",
                  fontWeight: "600",
                  fontSize: "22px",
                  lineHeight: "25.28px",
                  color: "#1C242C",
                  textAlign: "left",
                }}
                gutterBottom
              >
                Choose your pane style
              </Typography>
              <Grid container spacing={4}>
                <Grid
                  className="color-wrapper"
                  item
                  xs={4}
                  sx={{ marginTop: "1rem" }}
                >
                  {" "}
                  <input
                    className={`${theme.palette.background.default === "#ffffff"
                      ? "input-border"
                      : ""
                      }`}
                    type="color"
                    value={theme.palette.background.default}
                    name="palette.background.default"
                    onChange={handleColorChange}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  />{" "}
                  <label
                    htmlFor="Background"
                    style={{
                      fontWeight: "600",
                      fontSize: "1.5rem",
                      marginLeft: "10px",
                    }}
                  >
                    Background
                  </label>
                </Grid>
                <Grid
                  className="color-wrapper"
                  item
                  xs={4}
                  sx={{ marginTop: "1rem" }}
                >
                  {" "}
                  <input
                    type="color"
                    value={theme.palette.success.main}
                    name="palette.success.main"
                    onChange={handleColorChange}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  />
                  <label
                    htmlFor="Success"
                    style={{
                      fontWeight: "600",
                      fontSize: "1.5rem",
                      marginLeft: "10px",
                    }}
                  >
                    Divider
                  </label>
                </Grid>
              </Grid>
              <Typography
                sx={{
                  fontFamily: "Ubuntu, sans-serif",
                  fontWeight: "600",
                  fontSize: "22px",
                  lineHeight: "25.28px",
                  color: "#1C242C",
                  textAlign: "left",
                }}
                gutterBottom
              >
                Choose your font style
              </Typography>
              <Grid container spacing={4}>
                <Grid
                  className="color-wrapper"
                  item
                  xs={4}
                  sx={{ marginTop: "1rem" }}
                >
                  {" "}
                  <input
                    type="color"
                    value={theme.palette.divider}
                    name="palette.divider"
                    onChange={handleColorChange}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  />{" "}
                  <label
                    htmlFor="Divider"
                    style={{
                      fontWeight: "600",
                      fontSize: "1.5rem",
                      marginLeft: "10px",
                    }}
                  >
                    Text
                  </label>
                </Grid>
              </Grid>
              <Typography
                sx={{
                  fontFamily: "Ubuntu, sans-serif",
                  fontWeight: "600",
                  fontSize: "22px",
                  lineHeight: "25.28px",
                  color: "#1C242C",
                  textAlign: "left",
                  marginBottom: "1rem",
                }}
                gutterBottom
              >
                Font Family
              </Typography>
              <Grid container spacing={4} className="theme">
                <Grid className="color-wrapper" item xs={8} sx={{ marginTop: "1rem", paddingTop: "1px" }}>
                  <TextFieldStyled
                    select
                    label="Font Family"
                    name="Ubuntu"
                    value={theme.font.primary.main.charAt(0).toUpperCase() + theme.font.primary.main.slice(1).toLowerCase()}
                    variant="outlined"
                    onChange={handleThemeChange}
                    fullWidth
                    displayEmpty

                  >
                    <MenuItem value="Ubuntu">Ubuntu</MenuItem>
                    <MenuItem value="Inter">Inter</MenuItem>
                  </TextFieldStyled>
                </Grid>
              </Grid>

              <Typography
                sx={{
                  fontFamily: "Ubuntu, sans-serif",
                  fontWeight: "600",
                  fontSize: "22px",
                  lineHeight: "25.28px",
                  color: "#1C242C",
                  textAlign: "left",
                  marginBottom: "1rem",
                }}
                gutterBottom
              >
                Button Styling
              </Typography>
              <Grid container spacing={4} className="theme">
                <Grid className="color-wrapper" item xs={8} sx={{ marginTop: "1rem", paddingTop: "1px" }}>
                  <TextFieldStyled
                    select
                    label="Border Radius"
                    name="buttonRadius.primary.main"
                    value={theme.buttonRadius.primary.main}
                    variant="outlined"
                    onChange={handleRadiusChange}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="2px">Sharpe Edges</MenuItem>
                    <MenuItem value="200px">Rounded Edges</MenuItem>
                  </TextFieldStyled>
                </Grid>
              </Grid>
              <Typography
                sx={{
                  fontFamily: "Ubuntu, sans-serif",
                  fontWeight: "600",
                  fontSize: "22px",
                  lineHeight: "25.28px",
                  color: "#1C242C",
                  textAlign: "left",
                  marginBottom: "1rem",
                }}
                gutterBottom
              >
                Button Outline
              </Typography>
              <Grid container spacing={4} className="theme">
                <Grid className="color-wrapper" item xs={8} sx={{ marginTop: "1rem", paddingTop: "1px" }}>
                  <TextFieldStyled
                    select
                    label="Border Outline"
                    name="borderOutline"
                    value={theme.borderOutline.currentOutline || "default"}
                    variant="outlined"
                    onChange={handleBorderChange}
                    fullWidth
                    displayEmpty
                  >
                    <MenuItem value="primary" sx={{ color: "green" }}>Success</MenuItem>
                    <MenuItem value="secondary" sx={{ color: "dodgerblue" }}>Info</MenuItem>
                    <MenuItem value="tertiary" sx={{ color: "red" }}>Danger</MenuItem>
                    <MenuItem value="default" sx={{ color: "black" }}>Default</MenuItem>
                  </TextFieldStyled>
                </Grid>
              </Grid>


            </div>
          )}
          {/* {activeStep === 4 && (
          )} */}

          {/* {activeStep === 5 && (

          )} */}
          {/* {activeStep === 6 && (
            
          )} */}
        </Box>
        <div style={{ display: "flex" }}>
          <Grid className="" sx={{ paddingBottom: "1rem" }}>
            <Grid item>

              {activeStep === 0 && (
                <>
                  <Button
                    sx={{
                      padding: "8px 40px",
                      backgroundColor: "#808080",
                      color: "#fff",
                      cursor: "pointer",
                      height: "45px",
                      flexGrow: "0",
                      fontFamily: "Ubuntu, sans-serif",
                      marginRight: "20px",
                      fontSize: "18px",
                      fontWeight: "700",
                      borderRadius: "30px",
                      lineHeight: "normal",
                      marginBottom: "1rem",
                      textTransform: "none",
                    }}
                    variant="contained"
                    color="primary"
                    onClick={updateApplicationData}
                    className="Update-btn"
                  >
                    Update
                  </Button>
                  <ToastContainer />
                </>
              )}
              {activeStep === 1 && (
                <Button
                  sx={{
                    padding: "8px 40px",
                    backgroundColor: "#808080",
                    color: "#fff",
                    cursor: "pointer",
                    height: "45px",
                    flexGrow: "0",
                    fontFamily: "Ubuntu, sans-serif",
                    marginRight: "20px",
                    fontSize: "18px",
                    fontWeight: "700",
                    borderRadius: "30px",
                    lineHeight: "normal",
                    marginBottom: "1rem",
                    textTransform: "none",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={editApplicationData}
                  className="Update-btn"
                >
                  Update
                </Button>
              )}
              {activeStep === 2 && (
                <>
                  {cardType === "api" ? (
                    <Button
                      sx={{
                        padding: "8px 40px",
                        backgroundColor: "#808080",
                        color: "#fff",
                        cursor: "pointer",
                        height: "45px",
                        flexGrow: "0",
                        fontFamily: "Ubuntu, sans-serif",

                        fontSize: "18px",
                        fontWeight: "700",
                        borderRadius: "30px",
                        lineHeight: "normal",
                        textTransform: "none",
                      }}
                      variant="contained"
                      color="primary"
                      onClick={editModuleApiData}
                      className="Update-btn"
                    >
                      Update
                    </Button>
                  ) : null}

                  {cardType === "list" && (
                    <Button
                      sx={{
                        padding: "8px 40px",
                        backgroundColor: "#808080",
                        color: "#fff",
                        cursor: "pointer",
                        height: "45px",
                        flexGrow: "0",
                        fontFamily: "Ubuntu, sans-serif",
                        fontSize: "18px",
                        fontWeight: "700",
                        borderRadius: "30px",
                        lineHeight: "normal",
                        textTransform: "none",
                      }}
                      variant="contained"
                      color="primary"
                      onClick={editModuleListData}
                      className="Update-btn"
                    >
                      Update
                    </Button>
                  )}

                  {cardType === "form" ? (
                    <Button
                      sx={{
                        padding: "8px 40px",
                        backgroundColor: "#808080",
                        color: "#fff",
                        cursor: "pointer",
                        height: "45px",
                        flexGrow: "0",
                        fontFamily: "Ubuntu, sans-serif",
                        marginRight: "20px",
                        fontSize: "18px",
                        fontWeight: "700",
                        borderRadius: "30px",
                        lineHeight: "normal",
                        textTransform: "none",
                      }}
                      variant="contained"
                      color="primary"
                      onClick={editFormData}
                      className="Update-btn"
                    >
                      Update
                    </Button>
                  ) : null}
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            className=""
            container
            spacing={2}
            sx={{ paddingBottom: "1rem" }}
          >
            <Grid item>
              {activeStep === steps.length - 1 ? (
                <Button
                  onClick={handleClick}
                  variant="contained"
                  color="primary"
                  sx={{
                    borderRadius: "30px",
                    padding: "8px 40px",
                    backgroundColor: "#1C242C",
                    color: "#fff",
                    cursor: "pointer",
                    height: "45px",
                    flexGrow: "0",
                    fontFamily: "Ubuntu, sans-serif",
                    marginRight: "20px",
                    fontSize: "18px",
                    fontWeight: "700",
                    lineHeight: "normal",
                  }}
                >
                  Finish
                </Button>
              ) : (
                <Button
                  className="buttom"
                  variant="contained"
                  color="primary"
                  onClick={handleNextStep}
                  style={{
                    borderRadius: "30px",
                    padding: "8px 40px",
                    backgroundColor: "#1C242C",
                    color: "#fff",
                    cursor: "pointer",
                    height: "45px",
                    flexGrow: "0",
                    fontFamily: "Ubuntu, sans-serif",
                    marginRight: "20px",
                    fontSize: "18px",
                    fontWeight: "700",
                    lineHeight: "normal",
                    textTransform: "none",
                  }}
                >
                  Continue
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Box>
  );
}

export default Form;
